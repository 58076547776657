import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

const AboutGroup = styled.div`
   display: grid;
  border-radius: 3px;
  position: relative;
  width: 70wv;
  margin: 1rem auto;
  box-shadow: 0 20px 40px ${({ theme }) => theme.text};


  /*desktop version*/
  @media (min-width: 750px) {
    margin-top: 1rem;
    display: grid;
    max-width: 76%;

  }

  h2 {
    padding-top: .5rem;
    margin: 0 auto;
    color: ${({ theme }) => theme.text};
    font-weight: 600;
    line-height: 1.3;
    text-decoration: underline;
    text-decoration-color: #d6ead7;
    
    /* mobile version */
    @media (max-width: 750px) {
      font-size: 1.5rem;
   }
    
  }
  h4 {
     font-size: .9rem;
     margin: .2rem auto;
     place-items: left;
     font-weight: 400;
    @media (max-width: 750px) {
      font-size: .8rem;
   }

  }
  p {
    font-size: .8rem;
    color: ${({ theme }) => theme.text};
    margin-bottom: 5px;
    text-align: center;
  }
  ul {
    margin-left: 1rem;
   padding-bottom: 2rem;
    place-items: center;

  }
  
  
`

export default function Servicii() {
   return (
      <Layout>

         <AboutGroup>
            <h2>Servicii</h2>
            <ul>
               <h4> - Turn-key Projects - Proiectare, Bugetare și Execuție</h4>
               <h4> - Cost management</h4>
               <h4> - Urmărire Achiziții</h4>
               <h4> - Grafice de Execuție </h4>
               <h4> - Monitorizare Proiect</h4>
            </ul>
            <h2>Sectoare de Activitate</h2>
            <ul>
               <h4> - Retail Malls / Hypermarkets</h4>
               <h4> - Birouri / Comercial</h4>
               <h4> - Productie /Logistica</h4>
               <h4> - Spitale / Farma</h4>
               <h4> - Hoteluri/ Restaurante</h4>
            </ul>
         </AboutGroup>
      </Layout >
   )
}
